<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <el-row>
        <el-col>
          <el-button type="primary" size="small" @click="addUsersDialog">添加用户</el-button>
        </el-col>
      </el-row>
      <!-- table表格区域 -->
      <el-table :data="userPage" border stripe v-loading="loading">
        <el-table-column align="center" label="id" prop="id" width="80px"></el-table-column>
        <el-table-column align="center" label="用户名" prop="userName"></el-table-column>
        <el-table-column align="center" label="用户昵称" prop="userNick"></el-table-column>
        <el-table-column align="center" label="角色名称" prop="roleName" :formatter="getRoleName"></el-table-column>
        <el-table-column align="center" label="账号类型" prop="type">
          <template slot-scope="scope">
            <el-tag disable-transitions>
              {{ scope.row.type == '0' ? '平台' : scope.row.type == '1' ? "省代理" : '市代理' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="城市">
          <template slot-scope="scope">
            <span>{{ scope.row.type === 1 ? scope.row.province : scope.row.type === 2 ? scope.row.city : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="最近登录时间" prop="lastLoginTime"></el-table-column>
        <el-table-column prop="status" align="center" label="状态" width="100">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status == 0 ? 'success' : 'danger'" disable-transitions>
              {{ scope.row.status == 0 ? '启用' : '停用' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime"></el-table-column>
        <el-table-column align="center" label="操作" width="200px">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="poenSetUsers(scope.row)">编辑</el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="delUsers(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                     :current-page="querInfo.current" :page-sizes="[5, 10, 15]" :page-size="querInfo.size"
                     layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </el-card>

    <!-- 添加用户对话框 -->
    <el-dialog title="添加用户" :visible.sync="addUserDialog" width="40%" :before-close="closeAddUser">
      <!-- 内容主题区域 -->
      <el-form :model="addUser" ref="addUserRef" :rules="rules">
        <el-form-item label="用户名:" prop="userName" label-width="130px">
          <el-input v-model="addUser.userName"></el-input>
        </el-form-item>
        <el-form-item label="密码:" prop="userPassword" label-width="130px">
          <el-input v-model="addUser.userPassword"></el-input>
        </el-form-item>
        <el-form-item label="用户昵称:" prop="userNick" label-width="130px">
          <el-input v-model="addUser.userNick"></el-input>
        </el-form-item>
        <el-form-item label="账号类型:" prop="type" label-width="130px">
          <el-radio-group v-model="addUser.type">
            <el-radio :label="0">平台</el-radio>
            <el-radio :label="1">省代理</el-radio>
            <el-radio :label="2">市代理</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="省份选择:" prop="province" v-if="addUser.type === 1" label-width="130px">
          <el-cascader placeholder="请选择省份" :props="{ value: 'label' }" :options="provinceOption"
                       v-model="addUser.province"></el-cascader>
        </el-form-item>
        <el-form-item label="城市选择:" prop="city" v-if="addUser.type === 2" label-width="130px">
          <el-cascader placeholder="请选择城市" :props="{ value: 'label' }" :options="options"
                       v-model="addUser.city"></el-cascader>
        </el-form-item>
        <el-form-item label="角色类型:" label-width="130px" prop="roleId">
          <template>
            <el-select v-model="addUser.roleId" clearable placeholder="请选择" style="width:250px">
              <el-option v-for="item in roleList" :key="item.id" :label="item.roleName" :value="item.id"></el-option>
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="状态:" label-width="130px">
          <el-switch v-model="addStatus" active-text="启用" inactive-text="停用"></el-switch>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeAddUser">取 消</el-button>
        <el-button type="primary" @click="handleAddUser">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑用户对话框 -->
    <el-dialog title="编辑用户" :visible.sync="setUserDialog" width="40%" :before-close="closeSetUser">
      <!-- 内容主题区域 -->
      <el-form :model="setUser" ref="setUserRef" :rules="rules">
        <el-form-item label="用户名:" prop="userName" label-width="130px">
          <el-input v-model="setUser.userName" disabled></el-input>
        </el-form-item>
        <el-form-item label="用户昵称:" prop="userNick" label-width="130px">
          <el-input v-model="setUser.userNick"></el-input>
        </el-form-item>
        <el-form-item label="账号类型:" prop="type" label-width="130px">
          <el-radio-group v-model="setUser.type">
            <el-radio :label="0">平台</el-radio>
            <el-radio :label="1">省代理</el-radio>
            <el-radio :label="2">市代理</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="省份选择:" prop="province" v-if="setUser.type === 1" label-width="130px">
          <el-cascader placeholder="请选择省份" :props="{ value: 'label' }" :options="provinceOption"
                       v-model="setUser.province"></el-cascader>
        </el-form-item>
        <el-form-item label="城市选择:" prop="city" v-if="setUser.type === 2" label-width="130px">
          <el-cascader placeholder="请选择城市" :props="{ value: 'label' }" :options="options"
                       v-model="setUser.city"></el-cascader>
        </el-form-item>
        <el-form-item label="角色类型:" label-width="130px" prop="roleId">
          <template>
            <el-select v-model="setUser.roleId" clearable placeholder="请选择" style="width:250px">
              <el-option v-for="(item,key) in roleList" :key="key" :label="item.roleName" :value="item.id"></el-option>
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="状态:" label-width="130px">
          <el-switch v-model="setStatus" active-text="启用" inactive-text="停用"></el-switch>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeSetUser">取 消</el-button>
        <el-button type="primary" @click="setUsers">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {GetUserPage, GetRoleList, AddUser, SetUser, DelUser} from "@/apis/system.js";
import {provinceAndCityData} from "element-china-area-data"; // 1.省市不带‘全部’的二级联动
export default {
  data() {
    return {
      querInfo: {
        // type: 3,
        current: 1,
        size: 10,
      },
      // 总数据条数
      total: 0,
      // 用户列表
      userPage: [],
      // 添加对话框开启
      addUserDialog: false,
      // 添加用户
      addUser: {
        userNick: "",
        userName: "",
        userPassword: "",
        roleId: "",
        status: "",
        type: 0,
      },
      rules: {
        userName: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        userPassword: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "请选择账号类型",
            trigger: "blur",
          },
        ],
        roleId: [
          {
            required: true,
            message: "请选择角色类型",
            trigger: "blur",
          },
        ],
        province: [
          {
            required: true,
            message: "请选择城市",
            trigger: "blur",
          }
        ],
        city: [
          {
            required: true,
            message: "请选择城市",
            trigger: "blur",
          }
        ]
      },
      addStatus: true,
      // 角色类型
      roleList: [],
      // 编辑对话框开启
      setUserDialog: false,
      // 编辑用户
      setUser: {
        userNick: "",
        userName: "",
        roleId: "",
        status: "",
        type: "",
      },
      setStatus: true,
      loading: false,
      options: provinceAndCityData,
      provinceOption: []
    };
  },
  created() {
    this.getUserPage();
    this.provinceOption = this.options.map(item => {
      return {
        label: item.label,
        value: item.value
      }
    })
  },
  methods: {
    getUserPage() {
      GetRoleList({}).then((data) => {
        this.roleList = data.data;
      });
      this.loading = true
      GetUserPage(this.querInfo).then((data) => {
        this.userPage = data.data.records;
        this.querInfo.current = data.data.current;
        this.querInfo.size = data.data.size;
        this.total = data.data.total;
      });
      setTimeout(() => {
        this.loading = false
      }, 50)
    },
    getRoleName(row, column) {
      let name = "";
      this.roleList.forEach((i) => {
        if (row.roleId == i.id) {
          name = i.roleName;
        }
      });
      return name;
    },

    // 监听 pagesize 改变
    handleSizeChange(newSize) {
      this.querInfo.size = newSize;
      this.getUserPage();
    },
    // 监听 pagenum 改变
    handleCurrentChange(newPage) {
      this.querInfo.current = newPage;
      this.getUserPage();
    },
    // 监听用户添加对话框的开启
    addUsersDialog() {
      this.addUserDialog = true;
      GetRoleList().then((data) => {
        this.roleList = data.data;
      });
    },
    // 添加
    handleAddUser() {
      this.$refs.addUserRef.validate((valid) => {
        if (valid) {
          if (this.addStatus) {
            this.addUser.status = 0;
          } else {
            this.addUser.status = 1;
          }
          if (this.addUser.type === 1) {
            this.addUser.province = this.addUser.province[0]
            this.addUser.city = ''
          } else if (this.addUser.type === 2) {
            this.addUser.city = this.addUser.city[0] + '-' + this.addUser.city[1]
            this.addUser.province = ''
          }
          AddUser(this.addUser).then((data) => {
            this.addUserDialog = false;
            this.$refs.addUserRef.resetFields();
            this.getUserPage();
          });
        }
      });
    },
    // 监听添加对话框的关闭事件，重置表单数据
    closeAddUser() {
      this.addUserDialog = false;
      this.addStatus = true;
      this.$refs.addUserRef.resetFields();
    },
    // 编辑对话框的开启
    poenSetUsers(scope) {
      this.setUserDialog = true;
      this.setUser = scope;
      if (scope.status == 0) {
        this.setStatus = true;
      } else {
        this.setStatus = false;
      }
      if (scope.type === 1) {
        this.setUser.province = scope.province.split('-')
      } else if (scope.type === 2) {
        this.setUser.city = scope.city.split('-')
      }
    },
    // 提交编辑
    setUsers() {
      this.$refs.setUserRef.validate((valid) => {
        if (valid) {
          if (this.setStatus) {
            this.setUser.status = 0;
          } else {
            this.setUser.status = 1;
          }
          if (this.setUser.type === 1) {
            this.setUser.province = this.setUser.province[0]
            this.setUser.city = ''
          } else if (this.setUser.type === 2) {
            this.setUser.city = this.setUser.city[0] + '-' + this.setUser.city[1]
            this.setUser.province = ''
          }
          SetUser(this.setUser).then((data) => {
            this.closeSetUser();
            if (data.code == 200) {
              this.$message({
                type: "success",
                message: data.msg,
              });
            }
          });
        }
      });
    },
    // 编辑用户框的关闭事件
    closeSetUser() {
      this.setUserDialog = false;
      this.getUserPage();
    },
    // 删除用户
    async delUsers(id) {
      const confirmResult = await this.$confirm(
          "此操作将永久删除该用户, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).catch((err) => err);
      if (confirmResult !== "confirm") {
        return this.$message.info("取消了删除！");
      }
      let data = {
        id: id,
      };
      DelUser(data).then((params) => {
        this.$message({
          type: "success",
          message: params.msg,
        });
        this.getUserPage();
      });
    },
  },
};
</script>
<style lang="less" scoped></style>
